import React, {
    FunctionComponent, useCallback, useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import CladAutofill from '@UIElements/CladAutofill';
import TextInput from '@UIElements/TextInput';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import { IKladrResultModel, IKladrResultModelSnapshotIn } from '@models/mobx-state-tree/kladrResult.model';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import { KladrResultsForAllFormsModelSnapshotIn } from '@models/mobx-state-tree/KladrResultsForAllForms.model';

interface IZipeCode {
    zipList: IKladrResultModelSnapshotIn[];
    selectedKladrResult: KladrResultsForAllFormsModelSnapshotIn[];
    setSelectedKladrResult: (val: KladrResultsForAllFormsModelSnapshotIn[]) => void;
    clearAddressForm?: boolean;
}

const ZipCode: FunctionComponent<IZipeCode> = ({
    zipList,
    selectedKladrResult,
    setSelectedKladrResult,
    clearAddressForm,
}) => {
    const [{ currentOrder }, { t }] = useInstances(Store, I18NService);

    const {
        showApproveValidationErrors,
        isTryingSaveOrderWithStatusCreated,
        animationOnOrErrorFieldsActive,
        form: { customerZipAttribute, setZipByDistrict },
        selectedStatus,
    } = currentOrder;

    const onChangeZipCode = useCallback(
        (val: string) => {
            if (customerZipAttribute) {
                customerZipAttribute.setValue(val);
            }
        },
        [customerZipAttribute],
    );

    const onChangeKladrResultZipCode = useCallback(
        (val: IKladrResultModel) => {
            if (customerZipAttribute) {
                customerZipAttribute.setKladrResult(val);
            }
        },
        [customerZipAttribute],
    );

    const classForAnimation = useMemo<string | boolean>(
        () => !isTryingSaveOrderWithStatusCreated && animationOnOrErrorFieldsActive &&
            selectedStatus === OrderStatusesEnum.APPROVE && 'animate-me bounce',
        [animationOnOrErrorFieldsActive, isTryingSaveOrderWithStatusCreated, selectedStatus],
    );

    const isPossibilityOfValidation = useMemo<boolean>(
        () => !isTryingSaveOrderWithStatusCreated && showApproveValidationErrors &&
            selectedStatus === OrderStatusesEnum.APPROVE,
        [isTryingSaveOrderWithStatusCreated, showApproveValidationErrors, selectedStatus],
    );

    if (!customerZipAttribute) {
        return null;
    }

    const {
        id,
        isKlader,
        formAttributeId,
        isKladrOnly,
        value,
        label,
        isValid,
    } = customerZipAttribute;

    if (!formAttributeId) {
        return null;
    }

    if (isKlader && !setZipByDistrict || setZipByDistrict && zipList.length) {
        return (
            <CladAutofill
                id={id}
                className={cn([
                    'orderAddress__input',
                    !isValid && classForAnimation,
                ])}
                key={id}
                formAttributeId={formAttributeId}
                isKladerOnly={Boolean(isKladrOnly)}
                kladerInAllString={false}
                value={value}
                onChange={onChangeKladrResultZipCode}
                width="270px"
                label={label ? t(label) : ''}
                placeholder={label ? t(label) : ''}
                error={t('Поле не валидно', 'The field is not valid.')}
                isValid={isPossibilityOfValidation ? isValid : true}
                zipList={zipList}
                isKlader={isKlader}
                selectedKladrResult={selectedKladrResult}
                setSelectedKladrResult={setSelectedKladrResult}
                clearAddressForm={clearAddressForm}
            />
        );
    }

    return (
        <TextInput
            id={id}
            className={cn([
                'orderAddress__input',
                !isValid && classForAnimation,
            ])}
            width="270px"
            label={label ? t(label) : ''}
            placeholder={label ? t(label) : ''}
            value={value}
            error={t('Поле не валидно', 'The field is not valid.')}
            onChangeValue={onChangeZipCode}
            isValid={isPossibilityOfValidation ? isValid : true}
        />
    );
};


export default observer(ZipCode);
