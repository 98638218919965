import React, {
    FunctionComponent, lazy, Suspense, useMemo, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import cn from 'classnames';

import TranslationService from '@services/I18NService';
import TextInput from '@UIElements/TextInput';
import TextArea from '@UIElements/TextArea';
import CladAutofill from '@UIElements/CladAutofill';
import ContentBlock from '@UIElements/ContentBlock';
import CountrySelect from '@UIElements/CountrySelect';
import { OrderService } from '@/app/services';
import { Store } from '@store/store';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import { IKladrResultModel, IKladrResultModelSnapshotIn } from '@models/mobx-state-tree/kladrResult.model';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import { AttrsKey } from '@interfaces/form.interface';
import Button from '@UIElements/Button';
import GeoNegociosMapService from '@services/GeoNegociosMapService';

import ZipCode from './ZipCode';

import { KladrResultsForAllFormsModelSnapshotIn } from '@models/mobx-state-tree/KladrResultsForAllForms.model';

const GeoNegociosMap = lazy<FunctionComponent>(async () => import('./geo-negocios-map'));

const OrderAddress: FunctionComponent = () => {
    const [
        { customerAddress },
        {
            currentOrder: {
                showApproveValidationErrors,
                country,
                animationOnOrErrorFieldsActive,
                isTryingSaveOrderWithStatusCreated,
                form: {
                    addressAttributesForForm,
                    useGeonegociosMap,
                    kladrResultsForAllForms,
                    clearKladrResultsForAllForms,
                },
                isCountryMexico,
                isCountryPeru,
                selectedStatus,
            },
        },
        { t },
        {
            showAddressOnMap,
            abilityToSearchForAddressesOnMap,
        },
    ] = useInstances(
        OrderService,
        Store,
        TranslationService,
        GeoNegociosMapService,
    );

    const isPossibilityOfValidation = useMemo<boolean>(
        () => !isTryingSaveOrderWithStatusCreated && showApproveValidationErrors && selectedStatus === OrderStatusesEnum.APPROVE,
        [isTryingSaveOrderWithStatusCreated, showApproveValidationErrors, selectedStatus],
    );

    const classForAnimation = useMemo<string | boolean>(
        () => !isTryingSaveOrderWithStatusCreated && animationOnOrErrorFieldsActive &&
            selectedStatus === OrderStatusesEnum.APPROVE && 'animate-me bounce',
        [animationOnOrErrorFieldsActive, isTryingSaveOrderWithStatusCreated, selectedStatus],
    );

    const isShowMap = (isCountryMexico || isCountryPeru) && useGeonegociosMap;

    const [zipList, setZipList] = useState<IKladrResultModelSnapshotIn[]>([]);
    const [selectedKladrResult, setSelectedKladrResult] = useState<KladrResultsForAllFormsModelSnapshotIn[]>([]);

    const [clearAddressForm, setClearAddressForm] = useState<boolean>(false);

    const clearForm = () => {
        setClearAddressForm(true);
        clearKladrResultsForAllForms();
        setZipList([]);
        setTimeout(() => {
            setClearAddressForm(false);
        });
    };

    return (
        <ContentBlock title={t('Адрес', 'Address')}>
            <>
                {isShowMap && (
                    <Suspense
                        fallback={<div>{t('Загрузка карты', 'Loading the map')}</div>}
                    >
                        <GeoNegociosMap />
                    </Suspense>
                )}
                <div className="orderAddress__country-form">
                    <div className="orderAddress__country-form__row-on-mobile">
                        <CountrySelect
                            disabled
                            className="orderAddress__input"
                            values={[]}
                            onSelect={() => ({})}
                            value={country}
                            width="105px"
                        />
                        <ZipCode
                            zipList={zipList}
                            selectedKladrResult={selectedKladrResult}
                            setSelectedKladrResult={setSelectedKladrResult}
                            clearAddressForm={clearAddressForm}
                        />
                    </div>
                    <div className="orderAddress__country-form-label">
                        {t(
                            'Введите почтовый индекс и работайте со всеми полями формы',
                            'Enter postcode and work with all form fields',
                        )}
                        <div className="orderAddress__country-form-button">
                            <Button
                                variant="3"
                                size="1"
                                text={t('Очистить', 'Clear')}
                                width="120px"
                                className={'status'}
                                onClick={clearForm}
                                disabled={!kladrResultsForAllForms.length}
                            ></Button>
                        </div>
                    </div>
                    {isShowMap && (
                        <Button
                            className="orderAddress__showAddressOnMap"
                            variant={!abilityToSearchForAddressesOnMap ? 5 : 3}
                            text={t('Показать адрес на карте', 'Show address on map')}
                            onClick={showAddressOnMap}
                            disabled={!abilityToSearchForAddressesOnMap}
                            titleForTooltip={!abilityToSearchForAddressesOnMap
                                ? t(
                                    'Заполните обязательные данные - улицу и дом',
                                    'Fill in the obligatory data - street and house',
                                )
                                : null
                            }
                        />
                    )}
                </div>
                <div className="orderAddress__main-form">
                    <div className="orderAddress__main-form--addresses">
                        {
                            addressAttributesForForm
                                .map((x: IFormAttributesModel): JSX.Element => {
                                    const isMinLengthValid = x.getIsMinLengthValid(selectedStatus);
                                    const isValid = x.isValid && isMinLengthValid;
                                    // Все три параметра будут нужны для обращения к endpoint /kladr-country/get-kladr-data
                                    // Если их не будет - компонент не будет работать корректно
                                    if (x.isKlader && x.formAttributeId) {
                                        return (
                                            <CladAutofill
                                                id={x.name || x.id}
                                                className={cn([
                                                    'orderAddress__input',
                                                    !isValid && classForAnimation,
                                                ])}
                                                key={x.id}
                                                formAttributeId={x.formAttributeId!}
                                                isKladerOnly={x.isKladrOnly || false}
                                                kladerInAllString={false}
                                                value={x.value}
                                                length={x.length}
                                                onChange={(val: IKladrResultModel) => x.setKladrResult(val)}
                                                width="280px"
                                                label={x.label ? t(x.label) : ''}
                                                placeholder={x.label ? t(x.label) : ''}
                                                error={x.textError}
                                                isValid={isPossibilityOfValidation ? isValid : true}
                                                isKlader={x.isKlader}
                                                setZipList={setZipList}
                                                zipList={zipList}
                                                selectedKladrResult={selectedKladrResult}
                                                setSelectedKladrResult={setSelectedKladrResult}
                                                clearAddressForm={clearAddressForm}
                                            />
                                        );
                                    }

                                    return (
                                        <TextInput
                                            className={cn([
                                                'orderAddress__input',
                                                !x.isValid && classForAnimation,
                                            ])}
                                            key={x.id}
                                            id={x.name || x.id}
                                            placeholder={x.label ? t(x.label) : ''}
                                            label={x.label ? t(x.label) : ''}
                                            width="280px"
                                            value={x.value}
                                            onChangeValue={(val: string) => x.setValue(val)}
                                            error={t('Поле не валидно', 'The field is not valid.')}
                                            isValid={isPossibilityOfValidation ? x.isValid : true}
                                            options={x.data}
                                        />
                                    );
                                })
                        }
                    </div>
                    <div className="orderAddress__main-form--customAddresses">
                        <TextArea
                            id={AttrsKey.CUSTOMER_ADDRESS}
                            placeholder={t('Адреса, отправленные клиентом', 'Adresses sent by client')}
                            label={t('Адреса, отправленные клиентом', 'Adresses sent by client')}
                            width="100%"
                            height="110px"
                            value={customerAddress}
                            disabled
                        />
                    </div>
                </div>
            </>
        </ContentBlock>
    );
};


export default observer(OrderAddress);
