import React, {
    FunctionComponent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import ScriptDataService from '@services/script/ScriptDataService';
import Select from '@UIElements/Select';
import CallButton from '@UIElements/CallButton';
import { ISelectedValue } from '@UIElements/Select/models';
import SipService from '@services/sip/SipService';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import { Store } from '@store/store';
import { EnumSipStatus } from '@services/sip/models';
import cn from 'classnames';
import MicOffSvg from '@components/main/order-page/call-panel/call-widget/includes/MicOffSvg';
import ProblemBtn from '@components/main/order-page/call-panel/call-widget/call-button-and-phone/includes/ProblemBtn';
import CallAutomatizationHandler from '@/app/services/call-automatization/CallAutomatizationHandler';
import { AttrsKey } from '@/app/interfaces/form.interface';
import AutodialCounter from '@/app/ui-elements/AutodialCounter/AutodialCounter';
import { EnumTypeOrder } from '@components/main/order-page/call-panel/call-widget/CallWidget';

const TIME_BUTTON_DISABLED = 5000;

const CallButtonAndPhone: FunctionComponent = (): JSX.Element => {
    const [
        { t },
        store,
        sipService,
        callAutomatization,
        scriptDataService,
    ] = useInstances(
        I18NService,
        Store,
        SipService,
        CallAutomatizationHandler,
        ScriptDataService,
    );
    const { callAutomatizationState } = callAutomatization;
    const [callWereMade, callsAvailable] = callAutomatizationState;
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { currentOrder, currentUser } = store;

    const {
        forceInactiveButtonOfCall,
        phonesToCallForCallButton,
        customerFullName,
        selectedPhoneToCallForComponent,
        communicationsQualityValues,
        lastCallHistoryEvent,
        isACreatedOrder,
        startCallAutomatic,
        isEmptyCurrentOrder,
        countryId,
        partnerProductId,
        orderType,
        id: orderId,
        form: {
            disableStatusSubStatusPhoneButtons,
        },
        abilityToDropACall,
        callsCount: {
            isBlockDueToCallLimit,
            numberOfCallAttempts,
        },
    } = currentOrder;

    const s = selectedPhoneToCallForComponent();

    const {
        status: sipStatus,
        muted,
        isCallingRightNow,
    } = sipService;

    const lastCallHistoryEventId = !!lastCallHistoryEvent && lastCallHistoryEvent.id;

    const communicationQualityWasSent = !!lastCallHistoryEvent && lastCallHistoryEvent.communicationQualityWasSent;

    useEffect(() => {

        if (isCallingRightNow) {
            setIsButtonDisabled(false);
        }

    }, [isCallingRightNow]);

    const onCallClick = useCallback(() => {

        if (isButtonDisabled) {
            return;
        }

        /*
      Блокируем кнопку звонка, на время TIME_BUTTON_DISABLED или пока не начнется
      дозвон (isCallingRightNow), иначе некоторые операторы постоянно жмут
      на кнопку звонка (UI-1654)
       */

        if (!isCallingRightNow) {
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, TIME_BUTTON_DISABLED);
        }

        if (!isEmptyCurrentOrder && orderType.id !== EnumTypeOrder.CREATED) {

            if (!isCallingRightNow) {
                void scriptDataService.getScript(String(countryId), orderId, partnerProductId);
            }
        }

        if (!isCallingRightNow) {
            let phone = '';
            if (s) {
                phone = s.value;
            }
            void sipService.call(phone);
            currentOrder.incrementSessionCallCount();
        } else {
            sipService.endCall();
        }
    }, [s, isCallingRightNow, isButtonDisabled]);

    const onSelectedPhone = useCallback((selected: ISelectedValue<IFormAttributesModel>) => {
        const { id } = selected.value;
        currentOrder.setSelectedPhoneToCallId(id as AttrsKey);
    }, []);

    const problemButtonDisabled = communicationsQualityValues.length === 0 || !lastCallHistoryEventId ||
        communicationQualityWasSent || disableStatusSubStatusPhoneButtons || !abilityToDropACall;

    const blockedDueToCallLimit = useMemo((): boolean => {
        return isBlockDueToCallLimit(
            currentUser.mode,
            currentOrder.callAutomatization.automaticCallsAmount,
        );
    }, [numberOfCallAttempts]);

    const SelectPhoneNumber = () => {

        if (!isEmptyCurrentOrder) {
            return (
                <div className="call-info-inputs">
                    <Select
                        disabled={sipStatus !== EnumSipStatus.REGISTERED}
                        onSelect={onSelectedPhone}
                        values={phonesToCallForCallButton}
                        selected={selectedPhoneToCallForComponent()}
                        placeholder={t('Укажите номер для звонка', 'Enter the number to call')}
                        label={t('Телефон клиента', 'Customer phone')}
                        width="280px"
                        withoutCheckIcon
                        dataCyValue="call_info_select"
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <div className="call-widget__center">
            <div className="call-info">
                <div className="call-info-name">{customerFullName}</div>
                <SelectPhoneNumber />
            </div>
            <div className="call-time">
                <div className="call-time-row">
                    <div
                        className={cn([
                            'call-time-button',
                            'mic-off',
                            !(
                                sipStatus === EnumSipStatus.LIVE
                                || sipStatus === EnumSipStatus.DIALING
                                || sipStatus === EnumSipStatus.PROGRESS
                            ) && 'disabled',
                            muted && 'muted',
                        ])}
                        onClick={() => {
                            if (!(
                                sipStatus === EnumSipStatus.LIVE
                                || sipStatus === EnumSipStatus.DIALING
                                || sipStatus === EnumSipStatus.PROGRESS
                            )) {
                                return;
                            }

                            sipService.muted = !muted;
                        }}
                    >
                        <MicOffSvg />
                    </div>
                    <div className="call-button-action">
                        <CallButton
                            onClick={onCallClick}
                            status={sipStatus}
                            forceInactive={
                                forceInactiveButtonOfCall ||
                                //(startCallAutomatic && !callAutomatizationFinished) ||
                                disableStatusSubStatusPhoneButtons ||
                                (!abilityToDropACall && isCallingRightNow) ||
                                blockedDueToCallLimit || !orderId ||
                                isButtonDisabled
                            }
                        />
                        {startCallAutomatic && !isACreatedOrder && (
                            <div className="calls_counter">
                                <AutodialCounter
                                    value={callWereMade}
                                    lastAttempt={callWereMade === callsAvailable}
                                />
                            </div>
                        )}
                    </div>
                    <ProblemBtn
                        isDisable={problemButtonDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(CallButtonAndPhone);
