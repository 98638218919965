import React, { FunctionComponent } from 'react';
import { ICurrencyModel } from '@models/mobx-state-tree/currency.model';

import './CollapseSummary.scss';


interface ICollapsedSummaryProps {
    className?: string;
    title?: string;
    summaryBody: JSX.Element;
    sum: number;
    currency: ICurrencyModel | null;
}


const CollapseSummary: FunctionComponent<ICollapsedSummaryProps> = ({
    title,
    summaryBody,
    sum,
    currency,
}): JSX.Element => {

    return (
        <div className="collapse-summary__wrapper">
            <div
                className='e-summary active'>
                <div
                    className="summary-head"
                >
                    <div className="summary-head-name">{title}</div>
                    <div className="summary-head-value">
                        {sum}
                        {currency && ` ${currency.code}`}
                    </div>
                </div>
                {summaryBody}
            </div>
        </div>
    );
};


export default CollapseSummary;
