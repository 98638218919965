import React, {
    ChangeEvent, FunctionComponent, useCallback, useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import I18NService from '@services/I18NService';
import Button from '@UIElements/Button';
import TextArea from '@UIElements/TextArea';
import DatepickerInput from '@UIElements/DatepickerInput';
import { Store } from '@store/store';
import CalendarContainer from '@components/main/order-page/order-shipping/includes/CalendarContainer';
import ModalService from '@core/services/ModalService';

import './includes/DateOfRecall.scss';


const DateOfRecall: FunctionComponent = () => {
    const [
        modalService,
        {
            currentOrder: {
                dateOfRecall,
                setOrderCommentDraft,
                orderCommentDraft,
                setDateOfRecall,
                country: {
                    recallLimitSeconds,
                },
                postProcessing,
                form: {
                    disableStatusSubStatusPhoneButtons: disabled,
                },
            },
            inactivityTimer: {
                stopAllTimers,
            },
        },
        {
            t,
            dateFnsLocale,
        },
    ] = useInstances(
        ModalService,
        Store,
        I18NService,
    );

    const onChangeDateRecall = useCallback((date: Date) => {

        /* Если дата и время меньше чем текущая дата - значит это сегодняшний день и время меньше чем текущее, значит
        устанавливаем текущее время
         */
        const minDateTime = new Date();

        setDateOfRecall(date > minDateTime ? date : minDateTime);

    }, [dateOfRecall]);

    const now = new Date(); // текущие дата и время // Текущее время

    /** Если дата не выбрана считаем,что день сегодняшний */
    const isToday = dateOfRecall?.toDateString() === now.toDateString() || dateOfRecall === null || false; // Проверяем, выбрана ли текущая дата

    const conditionalProps = isToday ?
        {
            minTime: now, // Устанавливаем минимальное время
            maxTime: new Date().setHours(23, 59), // макисмальное время до конца суток
        } :
        {};

    const submit = useCallback(() => {
        modalService.success();
        if (postProcessing) {
            stopAllTimers();
            modalService.clearModalStack();
        }
    }, []);

    const onChangeComment = useCallback((val: ChangeEvent<HTMLInputElement>) => {
        setOrderCommentDraft(val.target.value);
    }, [orderCommentDraft]);

    const maxDate = useMemo<Date | null>(
        () => (recallLimitSeconds
            ? moment().add(recallLimitSeconds, 'seconds').toDate()
            : null),
        [recallLimitSeconds],
    );

    return (
        <div className="dateOfRecall">
            <div className="dateOfRecall__content">
                <div className="dateOfRecall__field">
                    <ReactDatePicker
                        timeCaption={t('Время', 'Time')}
                        locale={dateFnsLocale}
                        onChange={onChangeDateRecall}
                        selected={dateOfRecall}
                        minDate={new Date()}
                        maxDate={maxDate}
                        { ...conditionalProps }
                        customInput={<DatepickerInput placeholderText={t('Дата повторного звонка', 'Recall date')} />}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        popperContainer={CalendarContainer}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        disabled={disabled}
                    />
                </div>
                <div className="dateOfRecall__field">
                    <TextArea
                        placeholder={t('Комментарий', 'Comment')}
                        label={t('Комментарий', 'Comment')}
                        width="450px"
                        value={orderCommentDraft}
                        onChange={onChangeComment}
                        disabled={disabled}
                    />
                </div>
                <div className="dateOfRecall__submitBlock">
                    <Button
                        variant="7"
                        size="1"
                        text={t('Сохранить', 'Save')}
                        width="192px"
                        className="dateOfRecall__send-button"
                        onClick={submit}
                        disabled={!dateOfRecall || disabled}
                    />
                </div>
            </div>
        </div>
    );
};


export default observer(DateOfRecall);
