import moment from 'moment-timezone';

interface IRemainingTimeResult {
    time: string;
    totalMinutes: number;
}

// Число , округляем к меньшему значению, если число однозначное добавляем впереди ноль
const formatTime = (time: number): string => Math.floor(time).toString().padStart(2, '0');

export const getRemainingTime = (recallDateByCallCenter: string): IRemainingTimeResult => {

    // Преобразуем строку в объект даты с помощью moment
    const recallDate = moment(recallDateByCallCenter);

    // Получаем текущее время браузера с помощью moment
    const currentDate = moment();
    // Вычисляем разницу между датами
    const duration = moment.duration(recallDate.diff(currentDate));

    // Выяснем положительное или отрицательное число получается
    if (duration.asMilliseconds() < 0) {
        return {
            time: '',
            totalMinutes: 0,
        };
    }

    const totalMinutes = Math.floor(duration.asMinutes()); // все оставшееся время в минутах

    // Вычисляем полное количество часов и оставшиеся минуты
    const hours = formatTime(Math.floor(totalMinutes / 60)); // кол-во оставшихся часов,
    const minutes = formatTime(totalMinutes % 60); // оставшиеся минуты

    return {
        time: `${hours}:${minutes}`, // кол-во полных суток,
        totalMinutes,
    };

};